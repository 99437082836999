import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Wrapper from "../components/layout/wrapper/wrapper"
import BlockContent from "../components/blockContent/blockContent"
import Seo from "../components/seo/seo"

import SidebarForm from "../components/shared/sidebarForm/sidebarForm"
import Cta from "../components/cta/cta"
import CtaForm from "../components/ctaForm/ctaForm"

import styles from "./shared.module.scss"

const Event = ({ data, pageContext }) => {
  const { content: event, seo } = data.sanityEvent.tabs

  const steps = [
    {
      link: `events`,
      title: "Events",
    },
    {
      link: `events/${event.category.slug.current}`,
      title: event.category.title,
    },
  ]

  return (
    <Layout>
      <Seo
        isBlogPost={false}
        title={event.title}
        path={pageContext.path}
        image={event.featuredImage?.asset.url}
        breadcrumbs={steps}
        {...seo}
      />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={event.hero[0].children} subtitle={event.subtitle} />
      <Wrapper noPadding>
        <div className={styles.inner}>
          {event.form && !(event.form.link == null) ? (
            <div className="grid">
              <div className="gridItem small-12 medium-3 medium-offset-1">
                <SidebarForm link={event.form.link} title={event.form.title} />
              </div>
              <div className="gridItem small-12 medium-6 medium-offset-5">
                <h4>{event.dateDescription}</h4>
                <BlockContent blocks={event.richText} />
              </div>
            </div>
          ) : (
            <div className="grid">
              <div className="gridItem small-12 medium-10 medium-offset-1">
                <h4>{event.dateDescription}</h4>
                <BlockContent blocks={event.richText} />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
      {event.cta &&
        (event.cta?.__typename === "SanityCtaForm" ? (
          <CtaForm {...event.cta} background="blue" />
        ) : (
          <Cta {...event.cta} background="blue" />
        ))}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityEvent(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        content {
          hero: _rawHero
          subtitle
          richText: _rawRichText(resolveReferences: { maxDepth: 5 })
          form {
            link
            title
          }
          title
          subtitle
          dateDescription
          category {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          featuredImage {
            asset {
              fluid(maxWidth: 580) {
                ...GatsbySanityImageFluid
              }
            }
          }
          cta {
            ... on SanityCta {
              ...ctaFragment
            }
            ... on SanityCtaForm {
              ...ctaFormFragment
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default Event
